































































































































































import { Vue, Component, Watch } from 'vue-property-decorator'
import { Ability } from '@/types/Ability'
import { mapGetters, mapState } from 'vuex'
import ExaGenericTable from '@exatech-group/generic-table/src/GenericTable.vue'
import { checkIcone } from '@/utils/helpers'
import PopUpInfoIntervenant from '@/components/PopupInfoIntervenant.vue'

@Component({
    computed: {
        ...mapGetters('auth', ['authUser', 'can', 'cannot', 'isA', 'isNotA', 'user_session_id']),
        ...mapGetters('ensembleAffectationsExaminateurs', ['loading', 'examinateursAffectations', 'examinateurAffectationSelect', 'meta', 'links', 'totalRows', 'lastPage', 'totalPage']),
        ...mapState('groupeExaminateur', ['groupeExaminateurs']),
        ...mapState('groupeExaminateurUser', ['groupeExaminateurUsers', 'totalRows']),
        ...mapState('affectationResponsable', ['affectationResponsables']),
        ...mapGetters('auth', ['authUser', 'can', 'cannot', 'isA', 'isNotA', 'user_session_id']),
        ...mapState('auth', ['user', 'authUser', 'user_session_id'])
    },
    components: {
        ExaGenericTable,
        PopUpInfoIntervenant
    }
})

export default class OrganisationExaminateurs extends Vue {
    Ability = Ability
    filtres:    any         = []
    dataForTab: Array<any>  = []

    // Ensemble des colonnes du tableau
    genericfields = [
        { key: 'edit',         label: '',       sortable: false, class: '', type: 'action' },
        { key: 'centre.name', label: 'Centre d\'oral', sortable: true, class: '', type: 'text' },
        { key: 'concour_name', label: 'Filière', sortable: true, class: 'text-center', type: 'text' },
        { key: 'ensemble_name', label: 'Équipe', sortable: true, class: 'text-center', type: 'text' },
        { key: 'user.name', label: 'Examinateur', sortable: true, class: '', type: 'text' },
        { key: 'groupe_name', label: 'Groupe', sortable: true, class: '', type: 'text' },
        { key: 'is_responsable', label: 'Responsable', sortable: true, class: '', type: 'text' },
        { key: 'matiere_name', label: 'Matière', sortable: true, class: '', type: 'text' },
        { key: 'is_seances_consecutives_paires', label: 'Séances consécutives', sortable: true, class: '', type: 'text' }
    ]

    paramsLocal = {
        page: 1,
        sort: 'ensemble_name',
        direction: 'asc'
    }

    checkResponsableIsDisabled = true

    // ------------ gestion groupe -----------------------
    modeEditGroupe = false
    showGestionGroupes = false
    groupeTemp: any = null
    showModalMessageDeleteGroupe = false

    groupeExaminateurUserTempChangeHandler(data: any) {
        this.checkResponsableIsDisabled = !(data.groupe_examinateur_id !== null && data.groupe_examinateur_id !== 0);
    }

    openGestionGroupes () {
        this.$store.dispatch('groupeExaminateur/getGroupeExaminateurs').then(() => {
            this.showGestionGroupes = true
        })
    }

    closeGestionGroupes () {
        this.showGestionGroupes = false
        this.cancelEditGroupe()
    }

    addGroupe () {
        this.groupeTemp = {
            id: 0,
            session_id: this.$store.getters['auth/user_session_id'],
            name: '',
            responsable_id: null
        }
        this.modeEditGroupe = true
    }

    cancelEditGroupe () {
        this.groupeTemp = null
        this.modeEditGroupe = false
    }

    saveGroupe () {
        const idInfo = 't_info_' + Math.random()
        const infosToaster = {
            id: idInfo,
            toaster: 'b-toaster-top-right',
            variant: 'primary',
            noCloseButton: true,
            fade: true,
            noAutoHide: true
        }

        this.$bvToast.toast('Enregistrement en cours ...', infosToaster)

        const params: any = {
            id: this.groupeTemp.id,
            session_id: this.groupeTemp.session_id,
            name: this.groupeTemp.name,
            responsable_id: this.groupeTemp.responsable_id
        }
        this.$store.dispatch(`groupeExaminateur/${this.groupeTemp.id === 0 ? 'add' : 'update'}GroupeExaminateur`, params)
            .then(async () => {
                await this.$store.dispatch('groupeExaminateur/getGroupeExaminateurs')
                this.cancelEditGroupe()
                const idSucces = 't_succes_' + Math.random()
                const succesToaster = {
                    id: idSucces,
                    toaster: 'b-toaster-top-right',
                    variant: 'success',
                    noCloseButton: true,
                    fade: true,
                    autoHideDelay: 5000
                }
                this.$bvToast.toast('Enregistrement terminé.', succesToaster)
            })
            .catch((error) => {
                console.log('ko:' + error)
            })
            .finally(() => {
                this.$bvToast.hide(idInfo)
            })
    }

    editGroupe (groupe: any) {
        this.groupeTemp = {
            id: groupe.id,
            session_id: groupe.session_id,
            name: groupe.name,
            responsable_id: groupe.responsable_id
        }
        this.modeEditGroupe = true
    }

    deleteGroupe (groupe: any) {
        this.groupeTemp = {
            id: groupe.id,
            session_id: groupe.session_id,
            name: groupe.name,
            responsable_id: groupe.responsable_id
        }
        this.showModalMessageDeleteGroupe = true
    }

    cancelDeleteGroupe () {
        this.groupeTemp = null
        this.showModalMessageDeleteGroupe = false
    }

    deleteGroupeSuite () {
        const idInfo = 't_info_' + Math.random()
        const infosToaster = {
            id: idInfo,
            toaster: 'b-toaster-top-right',
            variant: 'primary',
            noCloseButton: true,
            fade: true,
            noAutoHide: true
        }

        this.$bvToast.toast('Supression en cours ...', infosToaster)

        this.$store.dispatch('groupeExaminateur/deleteGroupeExaminateur',  this.groupeTemp.id)
            .then(async () => {
                await this.$store.dispatch('groupeExaminateur/getGroupeExaminateurs')
                const idSucces = 't_succes_' + Math.random()
                const succesToaster = {
                    id: idSucces,
                    toaster: 'b-toaster-top-right',
                    variant: 'success',
                    noCloseButton: true,
                    fade: true,
                    autoHideDelay: 5000
                }

                this.$bvToast.toast('Suppression terminée.', succesToaster)
                this.cancelDeleteGroupe()
            })
            .catch((error) => {
                console.log('ko:' + error)
            })
            .finally(() => {
                this.$bvToast.hide(idInfo)
            })
    }

    // ----- info Correcteur
    user_id_temp = 0
    showModalInfoIntervenant = false
    infoIntervenantHandler (user_id: number) {
        this.user_id_temp  = user_id
        this.showModalInfoIntervenant = true
    }

    // ----------edit groupe examinateur user---------------
    groupeExaminateurUserTemp: any = null
    showModalEditGroupeExaminateurUser = false
    openEditGroupeExaminateurUser(data: any) {
        this.groupeExaminateurUserTemp = JSON.parse(JSON.stringify(data))
        this.groupeExaminateurUserTemp.responsable = this.groupeExaminateurUserTemp.groupeExaminateurUser && this.groupeExaminateurUserTemp.groupeExaminateurUser.groupeExaminateur && this.groupeExaminateurUserTemp.groupeExaminateurUser.groupeExaminateur.responsable_id === this.groupeExaminateurUserTemp.user_id
        if (this.groupeExaminateurUserTemp.groupeExaminateurUser && this.groupeExaminateurUserTemp.groupeExaminateurUser.groupeExaminateur) {
            this.groupeExaminateurUserTemp.groupe_examinateur_id = this.groupeExaminateurUserTemp.groupeExaminateurUser.groupeExaminateur.id
            this.checkResponsableIsDisabled = false
        } else {
            this.groupeExaminateurUserTemp.groupe_examinateur_id = null
            this.checkResponsableIsDisabled = true
        }
        this.$store.dispatch('groupeExaminateur/getGroupeExaminateurs').then(() => {
            this.showModalEditGroupeExaminateurUser = true
        })
    }

    closeEditGroupeExaminateurUser () {
        this.groupeExaminateurUserTemp = null
        this.showModalEditGroupeExaminateurUser = false
    }

    saveEditGroupeExaminateurUser () {
        const idInfo = 't_info_' + Math.random()
        const infosToaster = {
            id: idInfo,
            toaster: 'b-toaster-top-right',
            variant: 'primary',
            noCloseButton: true,
            fade: true,
            noAutoHide: true
        }

        this.$bvToast.toast('Enregistrement en cours ...', infosToaster)

        const params: any = {
            id: this.groupeExaminateurUserTemp.id,
            groupe_examinateur_id: this.groupeExaminateurUserTemp.groupe_examinateur_id !== 0 ? this.groupeExaminateurUserTemp.groupe_examinateur_id : null,
            responsable: this.groupeExaminateurUserTemp.responsable !== null ? this.groupeExaminateurUserTemp.responsable : false,
            seances_consecutives_paires: this.groupeExaminateurUserTemp.seances_consecutives_paires !== null && this.groupeExaminateurUserTemp.seances_consecutives_paires !== 0 ? this.groupeExaminateurUserTemp.seances_consecutives_paires : false
        }

        this.$store.dispatch('groupeExaminateurUser/updateGroupeExaminateurUser', params)
            .then(() => {
                this.closeEditGroupeExaminateurUser()
                const idSucces = 't_succes_' + Math.random()
                const succesToaster = {
                    id: idSucces,
                    toaster: 'b-toaster-top-right',
                    variant: 'success',
                    noCloseButton: true,
                    fade: true,
                    autoHideDelay: 5000
                }
                this.$bvToast.toast('Enregistrement terminé.', succesToaster)
                this.$store.dispatch('groupeExaminateurUser/getGroupeExaminateurUsers', this.paramsLocal).then((response) => {
                    this.setDataForGenericTab(response.data.data)
                })
            })
            .catch((error) => {
                console.log('ko:' + error)
            })
            .finally(() => {
                this.$bvToast.hide(idInfo)
            })
    }

    // ---------------------------------------------

    getFiliere(data: any) {
        return [
            ...new Set(data?.concours
                ?.map((concour: any) => concour.name) || [])
        ]?.join(', ') || ''
    }

    // Chargement des données du tableau
    setDataForGenericTab(poData: any, isLoadMore = false) {
        if (!isLoadMore) {
            this.dataForTab = []
        }
        if (poData) {
            for (const result of poData) {
                const can = this.$store.getters['auth/can'](Ability.ORAL_AFEX_MANAGE)
                const droit = checkIcone(Ability.ORAL_AFEX_MANAGE, can)

                let userName = ''

                if (result.user) {
                    userName = result.user.name + ' ' + result.user.first_name
                }

                const filiere = this.getFiliere(result.epreuveCorrection || result.ensemble.groupeEpreuve.epreuves[0])
                const matieres = this.getMatieresNames(result.epreuveCorrection || result.ensemble.groupeEpreuve.epreuves[0])

                const userNameClass = 'text-start text-info item_action'

                const iconResponsable: any[] = []
                const titleIconResponsable = 'Responsable du groupe'
                if (result.groupeExaminateurUser && result.groupeExaminateurUser.groupeExaminateur && result.groupeExaminateurUser.groupeExaminateur.responsable_id === result.user_id) {
                    iconResponsable.push({ name:'check', class:'text-success' })
                }
                const iconSeanceConsecutive: any[] = []
                const titleSeanceConsecutive = 'Souhaite des séances consécutives paires'
                if (result.seances_consecutives_paires) {
                    iconSeanceConsecutive.push({ name:'check', class:'text-success' })
                }

                const line = [
                    { label: droit.label, item: result, type: 'action', typeAction: 'edit', class: 'commons_first_action_button btn_action_ligne', icon:droit.icon, disabled: !droit.canValue },
                    { label: '', item: result.ensemble && result.ensemble.centre ? result.ensemble.centre.name : '-', type: 'text', typeAction: null, class: 'text-start' },
                    { label: '', item: filiere, type: 'text', typeAction: null, class: 'text-center' },
                    { label: '', item: result.ensemble.name, type: 'text', typeAction: null, class: 'text-center' },
                    { label: '', item: result.user.id, type: 'actionText', typeAction: 'infoIntervenant', class: userNameClass, text: userName },
                    { label: '', item: result.groupeExaminateurUser && result.groupeExaminateurUser.groupeExaminateur ? result.groupeExaminateurUser.groupeExaminateur.name : '', type: 'text', typeAction: null, class: 'text-center' },
                    { label: '', item: iconResponsable, type: 'icons', typeAction: null, class: 'text-center', title: titleIconResponsable },
                    { label: '', item: matieres, type: 'text', typeAction: null, class: '' },
                    { label: '', item: iconSeanceConsecutive, type: 'icons', typeAction: null, class: 'text-center', title: titleSeanceConsecutive }
                ]
                this.dataForTab.push(line)
            }
        }
    }

    getMatieresNames (data: any) {
        if (data && data.matiere) {
            return data.matiere.name
        } else {
            return ''
        }
    }

    /**
     * Formatage des datas pour l'affichage dans le tableau générique
     * this.$store.state.ensembleAffectationsExaminateurs.examinateursAffectations
     */
    setFiltersForGenericTab() {
        // Options filières
        const filieres = this.$store.getters['concour/banques']
        const options_filieres = []
        for (const f in filieres) {
            options_filieres.push({ index: filieres[f].id, name: filieres[f].name })
        }

        const matieres = this.$store.getters['matiere/matieres']
        const options_matieres = []
        for (const m in matieres) {
            options_matieres.push({ index: matieres[m].id, name: matieres[m].name })
        }

        this.filtres = [
            { libelle: 'Centre d\'oral', defautOptionlibelle: 'Rechercher un', model: 'centre.name', value: '', index: 'centre.id', datas: '', loading: false, options: { type: 'form', fieldsKey: 'centre.name' } },
            { libelle: 'Filière', defautOptionlibelle: 'Rechercher une', model: 'concour_id', value: '', index: 'concour_id',  datas: options_filieres, loading: false, options: { type: 'deroulant', fieldsKey: 'concour_name' } },
            { libelle: 'Équipe', defautOptionlibelle: 'Rechercher une', model: 'ensemble_name', value: '', index: 'ensemble_name',  datas: '', loading: false, options: { type: 'form', fieldsKey: 'ensemble_name' } },
            { libelle: 'Nom de correcteur', defautOptionlibelle: 'Rechercher un', model: 'user.name', value: '', index: 'user.id', datas: '', loading: false, options: { type: 'form', fieldsKey: 'user.name' } },
            { libelle: 'Matière', defautOptionlibelle: 'Rechercher une', model: 'matiere_name', value: '', index: 'matiere_name', datas: '', loading: false, options: { type: 'form', fieldsKey: 'matiere_name' } },
            { libelle: 'Nom de groupe', defautOptionlibelle: 'Rechercher un', model: 'groupe_name', value: '', index: 'groupe_name', datas: '', loading: false, options: { type: 'form', fieldsKey: 'groupe_name' } }

            // Filtre sur remplaçant pas fonctionnel pour le moment côté back.
            // { libelle: 'Remplaçant', defautOptionlibelle: 'Rechercher un', model: 'remplacant.name', value: '', index: 'remplacant.name', datas: '', loading: rootStore.loading, options: { type: 'form', fieldsKey: 'remplacant.name' }}
        ]
    }

    /**
     * Récupération des events du tableau
     * params[0] => l'action
     * params[1] => l'id de l'item
     */
    handleTableEvent (paParams: any): void
    {
        if (paParams && paParams[0] && paParams[1])
        {
            switch (paParams[0]) {
                case 'edit':
                    this.openEditGroupeExaminateurUser(paParams[1])
                    break
                case 'sortHandler':
                case 'filterHandler':
                    this.filtreSortHandler(paParams[1])
                    break
                case 'onLoadPage':
                    this.loadHandler(paParams[1])
                    break
                case 'infoIntervenant':
                    this.infoIntervenantHandler(paParams[1])
                    break
            }
        }
    }

    // Appelle une page lors du scroll
    loadHandler (params: any) {
        this.$store.dispatch('groupeExaminateurUser/getMoreGroupeExaminateurUsers', params)
            .then((response) => this.setDataForGenericTab(response.data.data))
    }

    filtreSortHandler (params: any) {
        if (JSON.stringify(params) !== JSON.stringify(this.paramsLocal)) {
            this.paramsLocal = params
            this.$store.dispatch('groupeExaminateurUser/getGroupeExaminateurUsers', params)
                .then((response) => this.setDataForGenericTab(response.data.data))
        }
    }

    @Watch('user_session_id')
    refreshInterface () {
        this.load()
    }

    load() {
        this.$store.dispatch('groupeExaminateurUser/getGroupeExaminateurUsers', this.paramsLocal).then((response) => {
            this.setDataForGenericTab(response.data.data)
            if (this.$store.getters['concour/banques'].length === 0) {
                this.$store.dispatch('concour/getConcoursActifs')
                    .then(() => this.setFiltersForGenericTab())
            } else {
                this.setFiltersForGenericTab()
            }
        })
    }

    mounted () {
        this.load()
    }
}
